<template>
  <div class="app">
    <div :class="{'box-shadow': blacklist}">
      <router-view></router-view>
<!--      <router-view name="login"></router-view>-->
    </div>
    <!-- 资源预览 -->
    <play-resources v-if="showPlayRes" />
    <!-- 新手任务 -->
    <new-task v-if="showTask" />
    <!-- 积分指引 -->
    <point-guide v-if="showPointGuide" />
  </div>
</template>
<script>
import { getMachineIdenFn } from '@/common/utils/system'
import { setMachineIden, getMachineIden } from '@/storage/viewStorage'
import PlayResources from '@/component/playResources'
import newTask from '@/component/newTask'
import pointGuide from '@/component/pointGuide'
// import { normal, qd } from '@/config/page.js'

export default {
  name: 'zkxdr_web',
  components: {
    PlayResources,
    newTask,
    pointGuide
  },
  computed: {
    isInElectron () {
      const flag = navigator.userAgent.indexOf('Electron') > -1
      return flag
    },
    showPointGuide () {
      return this.$store.state.baseCourse.showPointGuide
    },
    showTask () {
      return this.$store.state.baseCourse.showNewTask
    },
    showPlayRes () {
      return this.$store.state.baseCourse.showPlayResources
    },
    routeName () {
      return this.$route.name
    },
    blacklist () {
      return this.routeName !== 'Class' && this.routeName !== 'Countdown' && this.routeName !== 'RollCall'
    }
  },
  created () {
    this.getMachineIdenFn()
    this.handlerMessage()
    document.title = this.$store.state.appInfo.config.title
    document.addEventListener('WeixinJSBridgeReady', function onBridgeReady () {
    // 微信环境中，现在可以使用 WeixinJSBridge 的方法了
      window.WeixinJSBridge.invoke('setFontSizeCallback', { fontSize: 0 })
      window.WeixinJSBridge.on('menu:setfont', () => {
        window.WeixinJSBridge.invoke('setFontSizeCallback', { fontSize: 0 })
      })
    }, false)
  },
  methods: {
    callplugin (name, arg) {
      const cmd = {}
      cmd.cmd = 'plugin'
      cmd.data = {
        name: name, arg: arg
      }
      window.parent.postMessage(JSON.stringify(cmd), '*')
    },
    // 获取机器id
    getMachineIdenFn () {
      const machineIden = getMachineIden()
      if (machineIden) {
        this.$store.commit('cache/setMachineIdenFn', machineIden)
        return
      }
      const res = getMachineIdenFn()

      this.$store.commit('cache/setMachineIdenFn', res)
      setMachineIden(res)
    },
    handlerMessage () {
      window.addEventListener('message', (event) => {
        if (!(typeof event.data === 'string' && event.data.indexOf('}') > -1)) {
          return
        }
        const payload = JSON.parse(event.data)
        const cmd = payload.cmd
        const data = payload.data
        // console.log('<=', cmd, data)
        switch (cmd) {
          case 'visibleTask':
            this.$store.commit('baseCourse/setNewTask', true)
            break
          case 'task':
            this.$store.commit('baseCourse/setPointGuide', true)
            break
          case 'syncctx':
            // machine 也可以通过这个传递
            // console.log('处理conxtext信息')
            // for (const i in data) {
            //  // localStorage.setItem(i, data[i])
            // }
            break
          case 'getinfo':
            data.userId && this.$store.dispatch('user/getInfo', data.userId)
            break
          case 'router':
            this.$router.push({ path: data })
            break
          case 'clear':
            localStorage.clear()
            // 清除缓存数据
            break
          case 'logout':
            console.log('执行退出清session：', data)
            break
          default:
            break
        }
      })
    }
  }
}
</script>

<style lang="scss">
  .app {
    min-height: 100vh;
    //background: rgba(256, 256, 256, 0);
    //border-radius: $border-radius;
    font-family:"Microsoft YaHei",苹方,"SourceHanSansCN" !important;
    //padding:30px;
    padding: 0;
    overflow-x: hidden;
  }
  .box-shadow {
    width: 100%;
    height: auto;
    // height: 100vh;
    // overflow: hidden;
    // overflow-y: auto;
    //border-radius: 10px;
    //box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.2);
  }
.v-transfer-dom{
  .ivu-btn-primary{
    background-color: #0066FF;
  }
}
body {
  .ivu-btn-primary {
    background-color: #0066FF;
    border-color: #0066FF;
    &:hover {
      background-color: #0066FF;
      border-color: #0066FF;
    }
  }
  .ivu-btn-text:hover {
    color: #0066FF;
  }
  input {
    &:hover {
      outline-color: '#0066ff'
    }
  }
}
</style>
