import axios from '@/common/request/request'

// 用户登录
export const authorize = params => {
  return axios.request({
    url: 'auth/usercenter/login',
    data: params
  })
}
// 用户登录
export const authorizewithmachine = params => {
  return axios.request({
    method: 'post',
    url: 'auth/usercenter/authorizewithmachine',
    data: params
  })
}

// 用户登录
export const validateToken = token => {
  return axios.request({
    method: 'get',
    url: `/auth/usercenter/validateToken/${token}`
  })
}

// 用户登录
export const getInfo = userId => {
  return axios.request({
    method: 'get',
    url: 'system/ucUser/' + userId
  })
}

export const bindMachineIden = (machId, v) => {
  return axios.request({
    method: 'post',
    url: 'system/ucUser/bindMachineIden',
    data: { machineIden: machId, clientVersion: v }
  })
}

export const register = params => {
  return axios.request({
    url: 'system/ucAnonymous/teacherRegister',
    data: params
  })
}

export const loginout = () => {
  return axios.request({
    url: 'auth/usercenter/logout',
    method: 'delete'
  })
}

// 获取获取用户协议
export const useragreenment = params => {
  return axios.request({
    url: 'system/ucAnonymous/useragreement',
    params: params,
    method: 'get'
  })
}

export const resetpwd = params => {
  return axios.request({

    url: 'system/ucAnonymous/resetpassword',
    data: params
  })
}
export const sendsms = (mobile, bizType, uuid, code) => {
  return axios.request({
    url: 'system/ucAnonymous/getValidCode',
    data: { bindMobile: mobile, bizType: bizType, uuid: uuid, code: code }
  })
}
export const sendregistersms = (mobile) => {
  return sendsms(mobile, 1)
}
export const sendresetpwdsms = (mobile) => {
  return sendsms(mobile, 3)
}

// 短信登录
export const smslogin = params => {
  return axios.request({
    url: 'auth/usercenter/smslogin',
    data: params
  })
}

// 短信登录
export const registerEnable = () => {
  return axios.request({
    method: 'get',
    url: 'system/ucAnonymous/openKey/show_register'
  })
}

// 获取二维码地址
export const getQrCodeApi = () => {
  return axios.request({
    method: 'post',
    url: 'auth/usercenter/pc/createWechatLoginParam'
  })
}

// 获取当前 uuid 状态
export const getUuidApi = uuid => {
  return axios.request({
    method: 'post',
    url: 'auth/usercenter/pc/checkWechatLoginStatus',
    data: {
      uuidLogin: uuid
    }
  })
}

// 删除 uuid 数据
export const killUuidApi = uuid => {
  return axios.request({
    method: 'post',
    url: 'auth/usercenter/pc/killWeChatLoginCache',
    data: {
      uuidLogin: uuid
    }
  })
}
// 检测是否已注册
export const sendCheckSmsApi = (mobile, bizType) => {
  return axios.request({
    url: 'system/ucAnonymous/getValidCodeForWeb',
    data: { bindMobile: mobile, bizType: bizType }
  })
}

// 补全用户信息
export const postUserFillDataApi = data => {
  return axios.request({
    method: 'put',
    url: 'system/ucUser',
    data
  })
}

// 获取用户学校绑定列表
export const getUserBindSchlApi = bindMobile => {
  return axios.request({
    method: 'get',
    url: 'system/ucUserBindTeacher/pc/getApplyBindPersonalTeacher',
    params: {
      bindMobile
    }
  })
}

// 用户确定是否绑定学校
export const postBindSchlApi = (bindId, ifJoined) => {
  return axios.request({
    method: 'post',
    url: 'system/ucUserBindTeacher/pc/confirmBindPersonalTeacher',
    data: {
      bindId,
      ifJoined
    }
  })
}

// 获取用户科协接口地址
export const getCastBaseUrl = () => {
  return axios.request({
    method: 'get',
    url: 'system/config/openapi/getProjectAPiUrl'
  })
}

// 获取用户是否是管理员
export const hasWebAdminPermission = (partnerCode, openId) => {
  return axios.request({
    method: 'post',
    url: 'auth/partnerSysUserCenter/openapi/hasWebAdminPermission',
    data: {
      partnerCode,
      openId
    }
  })
}
// codeLogin
export const codeLoginApi = params => {
  return axios.request({
    url: 'auth/usercenter/loginByToken',
    data: params
  })
}

// 统一登录绑定
export const loginBindApi = () => {
  return axios.request({
    url: 'auth/sso/bindByBsk',
    method: 'post'
  })
}

// 统一登陆token获取
export const loginBySsoApi = () => {
  return axios.request({
    url: 'auth/usercenter/sso/loginBySso',
    method: 'post',
    data: {
      source: 1,
      project: 'BSK'
    }
  })
}

// 出版社登录后需要调的接口
export const refreshPressId = id => {
  return axios.request({
    method: 'post',
    url: `system/myInfo/refreshPressId/${id}`
  })
}
